import Swiper from 'swiper/swiper-bundle.min.js';
const MicroModal = require('micromodal');
import { listen, prefetch } from 'quicklink';
global.jQuery = global.$ = window.jQuery = require('jquery');

// quicklink
listen({
  origins: [
    'www.tacthome.co.jp',
    'lmp-stg.work'
  ],
  ignores: [
    /\/api\/?/,
    /#(.+)/,
    /tel:(.+)/,
    /mailto:(.+)/,
    uri => uri.includes('.zip'),
    (uri, elem) => elem.hasAttribute('noprefetch')
  ]
});

// グローバルナビ
const gnav = document.getElementById('panel-gnav');

// メガメニュー
const gnavCtrl = document.querySelectorAll('.js-menu-ctrl');
const gnavItem = document.querySelectorAll('.p-gnav__item');
const gnavPanel = document.querySelectorAll('.p-gnav__menu');

// ハンバーガーメニュー
const burger = document.getElementById('burger');

if(burger !== null){
  burger.addEventListener('click', (e) => {
    if (burger.getAttribute('aria-expanded') == 'false') {
      [].forEach.call(gnavPanel, (ctrl) => {
        ctrl.setAttribute('aria-hidden', false);
      });
      burger.setAttribute('aria-expanded', true);
      gnav.setAttribute('aria-hidden', false);
      document.documentElement.classList.add('fixed');
    } else {
      [].forEach.call(gnavPanel, (ctrl) => {
        ctrl.setAttribute('aria-hidden', true);
      });
      burger.setAttribute('aria-expanded', false);
      gnav.setAttribute('aria-hidden', true);
      document.documentElement.classList.remove('fixed');
    }
  });
}

// エリアマップ
const mapArea = document.querySelectorAll('.p-search-top__area > li > a');
const mapPref = document.querySelectorAll('.p-search-top__pref > li > a');
const mapPath =  document.querySelectorAll('.p-search-top__map path');
const areaOver = (target) => {
  [].forEach.call(document.querySelectorAll('path[data-area="' + target.getAttribute('data-area') + '"]'), (area) => {
    area.classList.add('active');
  });
};
const prefOver = (target) => {
  document.getElementById(target.getAttribute('data-pref')).classList.add('active');
};
const areaLeave = () => {
  [].forEach.call(mapPath, (map) => {
    map.classList.remove('active');
  });
};

// タブ
const tabs = document.querySelectorAll('[role="tablist"] [role="tab"]');
const tabpanels = document.querySelectorAll('[role="tabpanel"]');

[].forEach.call(tabs, (ctrl) => {
  ctrl.addEventListener('click', (e) =>{
    const target = e.currentTarget;

    [].forEach.call(tabs, (tab) => {
      tab.setAttribute("aria-selected", false);
    });

    target.setAttribute("aria-selected", true);

    [].forEach.call(tabpanels, (panel) => {
      panel.setAttribute("aria-hidden", true);
    });

    document.getElementById(target.getAttribute('aria-controls')).setAttribute('aria-hidden', false);

  });
});

//media query
// const mediaQuerySm = window.matchMedia('(min-width: 640px)');
const mediaQueryMd = window.matchMedia('(min-width: 768px)');
const mediaQueryLg = window.matchMedia('(min-width: 1024px)');
// const mediaQueryXl = window.matchMedia('(min-width: 1280px)');


// const mediaQuerySmListner = (mq) => {
//   if (mq.matches) { // 640px以上

//   } else { // 640px未満

//   }
// };

const mediaQueryMdListner = (mq) => {
  if (mq.matches) { // 768px以上
    // グローバルナビ
    if(gnav !== null) {
      gnav.setAttribute('aria-hidden', false);
      for (let i = 0; i < gnavCtrl.length; i++) {
        gnavCtrl[i].setAttribute('aria-expanded', false);
        gnavCtrl[i].style.display = 'block';
      }
    }

    // メガメニュー
    [].forEach.call(gnavPanel, (ctrl) => {
      ctrl.setAttribute('aria-hidden', true);
    });
    [].forEach.call(gnavItem, (ctrl) => {
      if(ctrl.querySelector('.js-menu-ctrl') !== null){
        ctrl.querySelector('.js-menu-ctrl').setAttribute('aria-expanded', false);
        ctrl.addEventListener('mouseenter', (e) => {
          for (let i = 0; i < gnavCtrl.length; i++) {
            gnavCtrl[i].setAttribute('aria-expanded', false);
          }
          for (let i = 0; i < gnavPanel.length; i++) {
            gnavPanel[i].setAttribute('aria-hidden', true);
          }
          const targetPanel = e.currentTarget.querySelector('.js-menu-ctrl').getAttribute('aria-controls');
          e.currentTarget.querySelector('.js-menu-ctrl').setAttribute('aria-expanded', true);
          document.getElementById(targetPanel).setAttribute('aria-hidden', false);
        });
        ctrl.addEventListener('mouseleave', (e) => {
          for (let i = 0; i < gnavCtrl.length; i++) {
            gnavCtrl[i].setAttribute('aria-expanded', false);
          }
          for (let i = 0; i < gnavPanel.length; i++) {
            gnavPanel[i].setAttribute('aria-hidden', true);
          }
        });
      }
    });

  } else { // 768px未満
    // グローバルナビ
    if(gnav !== null){
      gnav.setAttribute('aria-hidden', true);
      for (let i = 0; i < gnavCtrl.length; i++) {
        gnavCtrl[i].setAttribute('aria-expanded', false);
        gnavCtrl[i].style.display = 'none';
      }
    }

  }
};

const mediaQueryLgListner = (mq) => {
  if (mq.matches) { // 1024px以上
    [].forEach.call(mapArea, (area) => {
      area.addEventListener('mouseover', (e) => {
        areaOver(e.currentTarget);
      });
      area.addEventListener('mouseleave', (e) => {
        areaLeave();
      });
    });
    [].forEach.call(mapPref, (pref) => {
      pref.addEventListener('mouseover', (e) => {
        prefOver(e.currentTarget);
      });
      pref.addEventListener('mouseleave', (e) => {
        areaLeave();
      });
    });
  } else { // 1024px未満
    // トップページの場合タブパネル初期化
    const pathname = location.pathname.replace(/index.html|index.php/g, '');
    if(pathname == '/grafaresearch/'){
      [].forEach.call(tabs, (tab) => {
        tab.setAttribute("aria-selected", false);
      });
      document.querySelector('[aria-controls="panel-area"]').setAttribute("aria-selected", true);
      [].forEach.call(tabpanels, (panel) => {
        panel.setAttribute("aria-hidden", true);
      });
      document.getElementById('panel-area').setAttribute('aria-hidden', false);
    }
  }
};

// const mediaQueryXlListner = (mq) => {
//   if (mq.matches) { // 1280px以上

//   } else { // 1280px未満

//   }
// };

// mediaQuerySm.addEventListener("change", mediaQuerySmListner);
mediaQueryMd.addEventListener("change", mediaQueryMdListner);
mediaQueryLg.addEventListener("change", mediaQueryLgListner);
// mediaQueryXl.addEventListener("change", mediaQueryXlListner);

// mediaQuerySmListner(mediaQuerySm);
mediaQueryMdListner(mediaQueryMd);
mediaQueryLgListner(mediaQueryLg);
// mediaQueryXlListner(mediaQueryXl);

// 物件カルーセル
const swiperContainer = document.querySelectorAll('.js-swiper-card');
const prevSlideMessage = '前のスライドへ';
const nextSlideMessage = '次のスライドへ';
if(swiperContainer.length > 0) {
  const swiperCard = new Swiper ('.js-swiper-card', {
    slidesPerView: 'auto',
    spaceBetween: 24,
    grabCursor: true,
    navigation: {
      nextEl: '.js-swiper-card .swiper-button-next',
      prevEl: '.js-swiper-card .swiper-button-prev',
    },
    a11y: {
      prevSlideMessage: prevSlideMessage,
      nextSlideMessage: nextSlideMessage,
    },
    breakpoints: {
      768: {
        spaceBetween: 32,
      }
    },
  });
}

// アコーディオン
const acdCtrl = document.querySelectorAll('.js-accrodion');
[].forEach.call(acdCtrl, (ctrl) => {
  ctrl.addEventListener('click', (e) => {
    const targetPanel = e.currentTarget.getAttribute('aria-controls');
    const state = e.currentTarget.getAttribute('aria-expanded');
    if(state == 'false'){
      e.currentTarget.setAttribute('aria-expanded', true);
      e.currentTarget.setAttribute('aria-label', 'タブを閉じる');
      document.getElementById(targetPanel).setAttribute('aria-hidden', false);
    }else{
      e.currentTarget.setAttribute('aria-expanded', false);
      e.currentTarget.setAttribute('aria-label', 'タブを開く');
      document.getElementById(targetPanel).setAttribute('aria-hidden', true);
    }
  });
});

// ページトップボタン
const pagetopBtn = document.querySelector('#pagetop');
const ctaFixed = document.querySelector('.js-cta-fixed');
if(ctaFixed !== null) pagetopBtn.classList.add('bottom-lg');

// ページトップへスクロール
const scrollToTop = () => {
  pagetopBtn.addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  });
};
scrollToTop();

// ページ内リンクスムーススクロール（指定IDへスクロール）
const scrollToId = () => {
  const anchorLinks = document.querySelectorAll('a[href^="#"]');
  [].forEach.call(anchorLinks, (link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
      // 固定ヘッダーなどの固定要素がある場合はその分の高さを考慮
      // 固定要素のセレクタはコードに合わせて適宜変更
      //const headerHeight = document.querySelector('header') !== null ? document.querySelector('header').clientHeight : 0;
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      //const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top - headerHeight;
      const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
      window.scrollTo({
        top: targetOffsetTop,
        behavior: "smooth"
      });
    });
  });
};
scrollToId();

// トップページでハッシュ指定がある場合は指定のタブを開く
window.initSearchTab = () =>{
  const tabId = location.hash.replace('#', '');
  if(!tabId){
    return;
  }

  let scrollId = 'top-search';
  let ctrl = null;
  let target = null;

  if(!window.matchMedia('(min-width: 768px)').matches){
    //ハンバーガーメニューを閉じる
    [].forEach.call(gnavPanel, (ctrl) => {
      ctrl.setAttribute('aria-hidden', true);
    });
    burger.setAttribute('aria-expanded', false);
    gnav.setAttribute('aria-hidden', true);
    document.documentElement.classList.remove('fixed');
  }

  if(window.matchMedia('(min-width: 1024px)').matches){
    ctrl = document.querySelector('[aria-controls="panel-' + tabId + '"]');
    target = document.getElementById('panel-' + tabId);
    if(ctrl !== null && target !== null){
      [].forEach.call(document.querySelectorAll('[role="tablist"] [role="tab"]'), (tab) => {
        tab.setAttribute("aria-selected", false);
      });
      [].forEach.call(document.querySelectorAll('[role="tabpanel"]'), (panel) => {
        panel.setAttribute("aria-hidden", true);
      });
      ctrl.setAttribute('aria-selected', true);
      target.setAttribute('aria-hidden', false);
    }
  }else{
    if(/area|line|map/.test(tabId)){
      ctrl = document.querySelector('[aria-controls="panel-' + tabId + '"]');
      target = document.getElementById('panel-' + tabId);
      if(ctrl !== null && target !== null){
        [].forEach.call(document.querySelectorAll('[role="tablist"] [role="tab"]'), (tab) => {
          tab.setAttribute("aria-selected", false);
        });
        [].forEach.call(document.querySelectorAll('[role="tabpanel"]'), (panel) => {
          panel.setAttribute("aria-hidden", true);
        });
        ctrl.setAttribute('aria-selected', true);
        target.setAttribute('aria-hidden', false);
      }
    }else if(/commute|budget/.test(tabId)){
      scrollId = tabId;
      ctrl = document.querySelector('[aria-controls="panel-sp-' + tabId + '"]');
      target = document.getElementById('panel-sp-' + tabId);
      console.log(ctrl.getAttribute('aria-expanded') + ' ' + target.getAttribute('aria-hidden'));
      if(ctrl !== null && target !== null){
        ctrl.setAttribute('aria-expanded', true);
        ctrl.setAttribute('aria-label', 'タブを閉じる');
        target.setAttribute('aria-hidden', false);
      }
    }
  }

  // 所定位置にスクロール
  const targetOffsetTop = window.pageYOffset + document.getElementById(scrollId).getBoundingClientRect().top;
  window.scrollTo({
    top: targetOffsetTop,
    behavior: "smooth"
  });
};

// ハッシュ（ID）付きURLでロードされた場合
const loadScrollToId = () => {
  const targetId = location.hash;
  if(!targetId){
    return;
  }
  const targetElement = document.querySelector(targetId);
  if(targetElement === null){
    return;
  }
  const targetOffsetTop = window.pageYOffset + targetElement.getBoundingClientRect().top;
  window.scrollTo({
    top: targetOffsetTop,
    behavior: "smooth"
  });
};

// スクロール量取得
const getScrolled = () => {
  return (window.pageYOffset !== undefined) ? window.pageYOffset: document.documentElement.scrollTop;
}

// Dom読み込み時実行
// document.addEventListener('DOMContentLoaded', () => {
// });

// コンテンツ読み込み完了後に実行
window.addEventListener('load', () => {
  // ハッシュ付きURLの場合の位置変更
  loadScrollToId();

  //フェードインアニメーション
  const animElement = document.querySelectorAll('.js-effect-fadein');
  const animElementArr = Array.prototype.slice.call(animElement);
  const animElement2 = document.querySelectorAll('.js-effect-fadein-right');
  const animElementArr2 = Array.prototype.slice.call(animElement2);
  const animElement3 = document.querySelectorAll('.js-effect-fadein-left');
  const animElementArr3 = Array.prototype.slice.call(animElement3);

  const fadeinObserver = (entries, observer) => {
    [].forEach.call(entries, (entry) => {
      if(entry.isIntersecting) {
        entry.target.classList.add('inview');
        observer.unobserve(entry.target);
      }
    });
  }
  const options = {
    rootMargin: "-100px 0px"
  }
  const io = new IntersectionObserver(fadeinObserver, options);
  io.POLL_INTERVAL = 100;
  [].forEach.call(animElementArr, (el) => {
    io.observe(el);
  });
  [].forEach.call(animElementArr2, (el) => {
    io.observe(el);
  });
  [].forEach.call(animElementArr3, (el) => {
    io.observe(el);
  });
});

// スクロール時実行
window.addEventListener("scroll", () => {
  //ページトップボタン表示
  (getScrolled() > 100) ? pagetopBtn.classList.add('visible'): pagetopBtn.classList.remove('visible');
});
